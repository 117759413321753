import { getRegistrationDetails } from '@/api/registration'
import { getRoutineModule } from '@/store/routine'

import survey from './survey'
import user from './usera'

const module = getRoutineModule(
	'getRegistrationDetails',
	() => {
		return getRegistrationDetails()
	},
	{
		survey,
		user,
	}
)

export default module
