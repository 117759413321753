import { getStripeProducts, myPayment } from '@/api/payments'
import { getRoutineModule } from '@/store/routine'

const payment = getRoutineModule('myPayment', () => {
	return myPayment()
})
const module = getRoutineModule(
	'getStripeProducts',
	() => {
		return getStripeProducts()
	},
	{
		payment,
	}
)

export default module
