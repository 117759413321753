import { updateGaProperties } from '@/api/properties'
import { getRoutineModule } from '@/store/routine'
import { Properties } from '@/types/connection'
import { Dispatch } from 'vuex'

const module = getRoutineModule(
	'updateGAProperties',
	async (properties: Properties, dispatch: Dispatch) => {
		let response

		try {
			response = await updateGaProperties(properties)

			if (response) {
				// we updated the store document so we need to fetch the store details again
				dispatch('user/me/getUserInfo', null, { root: true })
			}
		} catch (error) {
			// @ts-ignore
			throw error?.message
		}

		return response
	}
)

export default module
