import { I18nextProvider } from 'react-i18next'

import i18n from '@/react/i18n'
import { RootStoreProvider } from '../../providers/RootStoreProvider'

const AppWrapper: React.FunctionComponent = ({ children }): JSX.Element => {
	return (
		<I18nextProvider i18n={i18n}>
			<RootStoreProvider>{children}</RootStoreProvider>
		</I18nextProvider>
	)
}

export default AppWrapper
