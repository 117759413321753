import queryString from 'query-string'
import { NavigationGuard } from 'vue-router'
import { BC_TOKEN_KEY } from '@/lib/constants'

const isAuthenticated: NavigationGuard = (_to, _from, next) => {
	let bcJwt = localStorage.getItem(BC_TOKEN_KEY)
	let { token } = queryString.parse(location.search)

	token = Array.isArray(token) ? token[0] : token

	if (token) {
		localStorage.setItem(BC_TOKEN_KEY, token)
		bcJwt = token
	}

	if (!bcJwt) {
		next({ path: '/error' })

		return false
	}

	next()
}

export { isAuthenticated }
