import { BC_TOKEN_KEY } from '@/lib/constants'
import store from '@/store'
import { HttpStatus } from '@/types/http'
interface ServiceOptions {
	url: string
	method: string
	body?: object
}

const { VUE_APP_API_URL } = process.env

export const xhrs = async (options: ServiceOptions) => {
	try {
		const response = await fetch(VUE_APP_API_URL + options.url, {
			method: options.method,
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem(BC_TOKEN_KEY)}`,
			},
			body: JSON.stringify(options.body),
		})
		const { tokenExpired } = store.state

		if (!response.ok) {
			if (response.status === HttpStatus.UNAUTHORIZED && tokenExpired !== true) {
				store.commit('updateTokenState', true, { root: true })
			}
			// if an error is returned with a valid JSON body read the message from the parsed JSON
			const { message } = await response.json()

			throw new Error(message)
		}

		if (response.status !== HttpStatus.UNAUTHORIZED && tokenExpired !== false) {
			store.commit('updateTokenState', false, { root: true })
		}

		switch (response.status) {
			case HttpStatus.NO_CONTENT:
				return {}
			default:
				return await response.json()
		}
	} catch (error) {
		console.warn(error)
		// @ts-ignore
		throw error
	}
}
