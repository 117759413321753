import { createLogger, createStore } from 'vuex'
import { state } from './state'
import user, { UserModuleState } from './modules/user'
import registration from './modules/registration'
import { connection } from './modules/connection'
import onboardingEntry from './modules/onboarding-entry'
import payments from './modules/payments'
import settings from './modules/settings'

export interface StoreState {
	user: UserModuleState
}

export default createStore({
	state,
	getters: {
		tokenState: (state) => state.tokenExpired,
	},
	mutations: {
		updateTokenState(state, payload: boolean) {
			state.tokenExpired = payload
		},
	},
	modules: {
		user,
		registration,
		connection,
		payments,
		settings,
		onboardingEntry,
	},
	plugins: process.env.NODE_ENV !== 'production' ? [createLogger()] : [],
})
