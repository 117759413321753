import { updatePipelineSettings } from '@/api/settings'
import { getRoutineModule } from '@/store/routine'
import { PipelineSettings } from '@/types/settings'
import { Dispatch } from 'vuex'
import pipeline from './pipeline'

const updateSettings = getRoutineModule(
	'updateSettings',
	async (payload: PipelineSettings, dispatch: Dispatch) => {
		const response = await updatePipelineSettings(payload)

		if (response) {
			// we updated the store document so we need to fetch the store details again
			dispatch('settings/pipeline/getPipelineSettings', null, { root: true })
		}

		return response
	}
)

export default {
	...updateSettings,
	modules: {
		pipeline,
	},
}
