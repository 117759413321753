import React from 'react'
import { useTranslation } from 'react-i18next'

import './Overlay.scss'

const Overlay: React.FC = () => {
	const { t } = useTranslation()

	return (
		<div className="overlay">
			<div className="notification_message">{t('common.unauthorized')}</div>
		</div>
	)
}

export default Overlay
