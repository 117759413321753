import { mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.name)
    ? (_openBlock(), _createElementBlock("img", _mergeProps({
        key: 0,
        src: require(`@/assets/svg-icons/${_ctx.name}.svg`)
      }, _ctx.$attrs), null, 16, _hoisted_1))
    : _createCommentVNode("", true)
}