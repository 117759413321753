import properties from './properties'

interface State {
	webPropertyId?: string
	measurementId?: string
	apiSecret?: string
}

export const connection = {
	namespaced: true,
	modules: {
		properties,
	},
	state: {
		webPropertyId: undefined,
	} as State,
	mutations: {
		setWebPropertyId: (state: State, webPropertyId: string) => {
			state.webPropertyId = webPropertyId
		},
		setMeasurementId: (state: State, measurementId: string) => {
			state.measurementId = measurementId
		},
		setApiSecret: (state: State, apiSecret: string) => {
			state.apiSecret = apiSecret
		},
	},
}
