import { OnboardingEntry } from '@/types/onboarding-entry'
import { xhrs } from './xhrs'

export const getOnboardingEntry = async (): Promise<OnboardingEntry> => {
	return await xhrs({
		url: '/onboarding-entry',
		method: 'GET',
	})
}

export const removeOnboardingEntry = async (): Promise<OnboardingEntry> => {
	return await xhrs({
		url: '/onboarding-entry',
		method: 'DELETE',
	})
}
