import { AuthResponse } from '@/api/user'
import { RoutineState } from '@/store/state'

import me from './me'
import sandbox from './sandbox'

export type UserMeModuleState = RoutineState<AuthResponse>
export type UserSandboxState = RoutineState<string>

export interface UserModuleState {
	me: UserMeModuleState
	sandbox: UserSandboxState
}

export default {
	namespaced: true,
	modules: {
		me,
		sandbox,
	},
}
