export enum SegmentEventTypes {
	PAGE_VIEW = 'Page View',
	USER_BEHAVIOR = 'User behavior tracking',
}

export namespace SegmentEvents {
	interface BaseEvent {
		storeId: string
		locationTitle: string
		virtualUrl: string
		poe?: string
	}

	export interface PageView extends BaseEvent {}

	export interface UserBehaviorTracking extends BaseEvent {
		fieldTitle: string
		actionId: string
		actionValue: string | string[]
		actionType: string
	}
}

export enum AppVirtualUrls {
	welcomeEmail = 'welcome-email',
	welcomeAbout = 'welcome-about',
	paywall = 'paywall',
	gaPropertyId = 'ga-property-id',
	measurementId = 'ga4-measurement-id',
	apiSecret = 'ga4-api-secret',
	disableBcGA = 'disable-bc-ga',
	ga3ThankYou = 'ga3-thank-you',
	pipelineSettings = 'pipeline-settings',
	applyScript = 'apply-script',
}

const buildEventPayload = (
	eventPayload: Omit<SegmentEvents.PageView | SegmentEvents.UserBehaviorTracking, 'storeId'>
) => {
	const poeName = localStorage.getItem('[ld]poeName')
	const payload = {
		storeId: `store-${localStorage.getItem('[ld]storeHash')}`,
		platform: 'BigCommerce',
		...eventPayload,
	}

	if (poeName) payload.poe = poeName

	return payload
}

export const sendPageViewEvent = (eventPayload: Omit<SegmentEvents.PageView, 'storeId'>) => {
	const payload = buildEventPayload(eventPayload)
	// @ts-ignore
	analytics.track(SegmentEventTypes.PAGE_VIEW, payload)
}
export const sendBehaviorEvent = (
	eventPayload: Omit<SegmentEvents.UserBehaviorTracking, 'storeId'>
): void => {
	const payload = buildEventPayload(eventPayload)
	// @ts-ignore
	analytics.track(SegmentEventTypes.USER_BEHAVIOR, payload)
}
