
import { defineComponent, PropType } from 'vue'

export default defineComponent({
	name: 'SVGIcon',
	props: {
		name: {
			type: String as PropType<string>,
			default: '',
		},
	},
})
