import { xhrs } from './xhrs'
import { UserSurvey } from '@/types/survey'

export interface SurveyResponse {
	notificationEmail: string
	survey: UserSurvey
}

export const updateSurveyRequest = async (survey: UserSurvey): Promise<SurveyResponse> => {
	return await xhrs({
		url: '/registration/survey',
		method: 'POST',
		body: survey,
	})
}
