import '@/scss/main.scss'

import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
// @ts-ignore
import WebFont from 'webfontloader'

import App from './App.vue'
import messages from './locale'
import router from './router'
import store from './store'
import Notifications from '@kyvg/vue3-notification'

// global components
import Icon from '@/components/SVGIcon/SVGIcon.vue'

WebFont.load({ typekit: { id: process.env.VUE_APP_TYPEKIT_ID } })

// load the intercom script, it must be loaded only once
// NOTE: 	this code only works here for some reason
// 			if you move this code to the intercom.ts service
// 			it will not work in circleCI
const APP_ID = process.env?.VUE_APP_INTERCOM_APP_ID
if (APP_ID) {
	/*eslint-disable*/
	// @ts-ignore
	window.intercomSettings = {
		app_id: APP_ID
	}
	// @ts-ignore
	;(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
	/*eslint-enable*/
}

export const i18n = createI18n({
	// set locale
	locale: 'en',
	// set fallback locale
	fallbackLocale: 'en',
	// set locale messages
	messages,
})

createApp(App)
	.use(store)
	.use(router)
	// Install i18n instance to make the whole app i18n-aware
	.use(i18n)
	.use(Notifications)
	// define global component
	.component('svg-icon', Icon)
	.mount('#app')
