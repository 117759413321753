import { ActionContext, Module, MutationTree, Dispatch } from 'vuex'
import { getRoutineState, RootState, RoutineState, Status } from './state'

export enum POSTFIX {
	STATE = 'State',
	SUCCESS = 'Success',
	ERROR = 'Error',
}

export const routineAction = <T, X, C>(
	actionName: string,
	fetchRequst: (payload: C, dispatch: Dispatch) => Promise<unknown>
) => {
	return async ({ commit, dispatch }: ActionContext<T, X>, payload: C) => {
		commit(`${actionName}${POSTFIX.STATE}`, Status.LOADING)

		try {
			const response = await fetchRequst(payload, dispatch)

			commit(`${actionName}${POSTFIX.SUCCESS}`, response)
		} catch (error) {
			commit(`${actionName}${POSTFIX.ERROR}`, error)
		} finally {
			commit(`${actionName}${POSTFIX.STATE}`, Status.LOADED)
		}
	}
}

export const routineMutation = <T, X>(actionName: string): MutationTree<RoutineState<T, X>> => {
	type State = RoutineState<T, X>

	return {
		[`${actionName}${POSTFIX.STATE}`]: (state: State, data: Status) => {
			state.state = data
		},
		[`${actionName}${POSTFIX.SUCCESS}`]: (state: State, data: T) => {
			state.data = data
		},
		[`${actionName}${POSTFIX.ERROR}`]: (state: State, error: X) => {
			state.error = error
		},
	}
}

export const routineGetters = <StateDataType>() => {
	return {
		getState: (state: RoutineState<StateDataType>) => state,
		isLoading: (state: RoutineState<StateDataType>) => state.state === Status.LOADING,
		isLoaded: (state: RoutineState<StateDataType>) => state.state === Status.LOADED,
	}
}

export const getRoutineModule = <DataType, ErrorType, PayloadState>(
	name: string,
	apiRequest: (payload: PayloadState, dispatch: Dispatch) => Promise<unknown>,
	modules: Record<string, Module<any, RootState>> = {}
) => {
	const state = getRoutineState<DataType, ErrorType>()
	const actions = {
		[name]: routineAction(name, apiRequest),
	}
	const mutations = routineMutation(name)
	const getters = routineGetters<DataType>()

	return {
		namespaced: true,
		state,
		actions,
		mutations,
		getters,
		modules,
	}
}
