import { Product, MyPayment, PaymentCheckout, BillingPortal } from '@/types/payment'
import { xhrs } from './xhrs'

export const getStripeProducts = async (): Promise<Product[]> => {
	return await xhrs({
		url: '/stripe/products',
		method: 'GET',
	})
}

export const initiateCheckout = async (priceId: string): Promise<PaymentCheckout> => {
	return await xhrs({
		url: `/stripe/checkout/${priceId}`,
		method: 'POST',
	})
}

export const myPayment = async (): Promise<MyPayment> => {
	return await xhrs({
		url: '/payments/my-payment',
		method: 'GET',
	})
}

export const updatePayment = async (): Promise<null> => {
	return await xhrs({
		url: '/payments/my-payment',
		method: 'PUT',
		body: { reinstate: true },
	})
}

export const initiateBillingPortalSession = async (): Promise<BillingPortal> => {
	return await xhrs({
		url: '/stripe/create-customer-portal-session',
		method: 'POST',
	})
}
