import ReactDOM from 'react-dom'

import AppWrapper from '@/react/components/AppWrapper/AppWrapper'
import React from 'react'

export const renderReactComponent = (
	Component: any,
	container: HTMLElement | null,
	props?: Record<string, unknown>
) => {
	if (container) {
		ReactDOM.render(
			React.createElement(AppWrapper, {}, [
				React.createElement(Component, { key: '1', ...props }),
			]),
			container
		)
	}
}
