import React, { createContext, ReactNode, useContext } from 'react'
import { RootStore } from '../store/root'

const store: RootStore = new RootStore()
const StoreContext = createContext<RootStore | undefined>(undefined)

StoreContext.displayName = 'StoreContext'

export const useRootStore = (): RootStore => {
	const context = useContext(StoreContext)

	if (context === undefined) {
		throw new Error('useRootStore must be used within RootStoreProvider')
	}

	return context
}

export const RootStoreProvider = ({ children }: { children: ReactNode }): JSX.Element => {
	return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
}
