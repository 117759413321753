import { xhrs } from './xhrs'
import { PipelineSettingsResponse, PipelineSettings } from '@/types/settings'

export const getPipelineSettings = async (): Promise<PipelineSettingsResponse> => {
	return await xhrs({
		url: '/settings',
		method: 'GET',
	})
}

export const updatePipelineSettings = async (
	payload: PipelineSettings
): Promise<PipelineSettingsResponse> => {
	return await xhrs({
		url: '/settings',
		method: 'PUT',
		body: payload,
	})
}
