
import { defineComponent, computed } from 'vue'

import '@/utils/import-frontend-components'

import Navbar from '@/react/components/Navbar/Navbar'
import Overlay from '@/react/components/Overlay/Overlay'
import { renderReactComponent } from '@/utils/react'
import { RouteNames } from '@/types/routes'
import { RouteRecordName } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
	setup() {
		const store = useStore()
		// don't show Navbar component on this routes
		const routesList: RouteRecordName[] = [
			RouteNames.ROOT,
			RouteNames.WELCOME,
			RouteNames.PAYWALL,
			RouteNames.ERROR,
			RouteNames.SANDBOX,
			RouteNames.NOT_FOUND,
		]

		return {
			hideNavbar: (routeName: RouteRecordName) =>
				routeName ? !routesList.includes(routeName) : false,
			showOverlay: computed(() => store.getters['tokenState']),
		}
	},
	mounted() {
		this.renderHeader()
	},
	updated() {
		this.renderHeader()
	},
	methods: {
		renderHeader() {
			renderReactComponent(Navbar, this.$refs.header as HTMLElement, {
				currentRoute: (this.$router.currentRoute.value as any).href,
				onRoute: (path: string) => this.$router.push(path),
			})

			renderReactComponent(Overlay, this.$refs.overlay as HTMLElement)
		},
	},
})
