import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { loadLocale } from './load'

i18n.use(initReactI18next).init({
	resources: loadLocale,
	debug: true,
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
	},
	react: {
		useSuspense: false,
	},
})

export default i18n
