import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
import store from '@/store'

export default {
	async hasRegistrationDetails(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext
	) {
		if (!store.getters['registration/isLoaded']) {
			await store.dispatch('registration/getRegistrationDetails')
		}
		next()
	},
	async hasOnboardingEntry(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext
	) {
		if (!store.getters['onboardingEntry/isLoaded']) {
			await store.dispatch('onboardingEntry/getOnboardingEntry')
		}
		next()
	},
	async hasUserDetails(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext
	) {
		if (!store.getters['user/me/isLoaded']) {
			await store.dispatch('user/me/getUserInfo')
		}
		next()
	},
	async hasPaymentDetails(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext
	) {
		if (!store.getters['payments/payment/isLoaded']) {
			await store.dispatch('payments/payment/myPayment')
		}
		next()
	},
	async hasSettingsDetails(
		to: RouteLocationNormalized,
		from: RouteLocationNormalized,
		next: NavigationGuardNext
	) {
		// always fetch settings before entering /settings route
		await store.dispatch('settings/pipeline/getPipelineSettings')

		next()
	},
}
