import { useState, SyntheticEvent, useCallback, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { RoutePaths } from '@/types/routes'
import { BC_HELP_URL } from '@/lib/constants'
import { sendBehaviorEvent } from '@/services/segmentTracking'
import { SettingsMenu } from './SettingsMenu'

import './Navbar.scss'

const Navbar = ({
	currentRoute,
	onRoute,
}: {
	currentRoute: string
	onRoute: (path: string) => void
}) => {
	const { t } = useTranslation()
	const [active, setActive] = useState(false)

	const gearRef = useRef(null)
	const [isSettingsMenuShown, setIsSettingsMenuShown] = useState<boolean>(false)
	const toggleSettingsMenu = () => setIsSettingsMenuShown(true)
	const closeMenu = () => setIsSettingsMenuShown(false)

	// toggle menu
	const toggleMenu = () => {
		const classList = document.getElementById('app')?.classList

		setActive(!active)
		classList?.toggle('overflow-hidden')
	}

	// hack to redirect by Vue.@todo remove after migrate to react.
	const redirect = (ev: SyntheticEvent) => {
		const { to } = (ev.currentTarget as HTMLElement).dataset

		if (to) {
			onRoute(to)
			if (window.innerWidth < 720) toggleMenu()
		}
	}

	// a hack for vue router. @todo remove after migrate to react.
	const isActive = useCallback(
		(link: string) => {
			return currentRoute?.includes(link)
		},
		[currentRoute]
	)

	const handleInfoClick = () => {
		sendBehaviorEvent({
			locationTitle: '',
			virtualUrl: currentRoute,
			fieldTitle: '',
			actionId: '',
			actionValue: BC_HELP_URL,
			actionType: 'link',
		})
	}

	const handleInfoClickOnMobile = () => {
		handleInfoClick()
		toggleMenu()
	}

	return (
		<nav className="navbar is-dark" aria-label="main navigation">
			<div className="navbar-brand">
				<div className="navbar-item">
					<img
						src={require('@/assets/svg-icons/ld-logo.svg')}
						height="28"
						alt="icon"
						className="logo"
					/>

					<img
						src={require('@/assets/svg-icons/ld-logo-small.svg')}
						height="24"
						alt="icon"
						className="logo-small"
					/>
				</div>

				<a
					role="button"
					className={classNames('navbar-burger', { 'is-active': active })}
					aria-label="menu"
					aria-expanded="false"
					data-target="navbarMobile"
					onClick={toggleMenu}
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div className="navbar-menu">
				<div className="navbar-start">
					<a
						className={classNames('navbar-item', {
							'router-link-active': isActive(RoutePaths.CONNECTION),
						})}
						data-to={RoutePaths.CONNECTION}
						onClick={redirect}
					>
						{t('navbar.menu.connections')}
					</a>
				</div>

				<div className="navbar-end">
					<a
						className={classNames('navbar-item', {
							'router-link-active': isActive(RoutePaths.SETTINGS),
						})}
						ref={gearRef}
						onClick={toggleSettingsMenu}
					>
						<img src={require('@/assets/svg-icons/settings.svg')} />
					</a>

					<a
						href={BC_HELP_URL}
						target="_blank"
						className="navbar-item help-link"
						rel="noreferrer"
						onClick={handleInfoClick}
					>
						<img src={require('@/assets/svg-icons/question.svg')} />
					</a>
				</div>
			</div>

			{isSettingsMenuShown && (
				<SettingsMenu targetRef={gearRef} closeMenu={closeMenu} onRoute={onRoute} />
			)}

			<div id="navbarMobile" className={classNames({ 'is-active': active })}>
				<div className="navbar-mobile">
					<a
						className={classNames('navbar-mobile-item', {
							'router-link-active': isActive(RoutePaths.CONNECTION),
						})}
						data-to={RoutePaths.CONNECTION}
						onClick={redirect}
					>
						<img src={require('@/assets/svg-icons/connections.svg')} />
						<span>{t('navbar.menu.connections')}</span>
					</a>

					<a
						className={classNames('navbar-mobile-item', {
							'router-link-active': isActive(RoutePaths.BILLING),
						})}
						data-to={RoutePaths.BILLING}
						onClick={redirect}
					>
						<img src={require('@/assets/svg-icons/billing.svg')} />
						<span>{t('navbar.menu.settings.billing')}</span>
					</a>

					<a
						className={classNames('navbar-mobile-item', {
							'router-link-active': isActive(RoutePaths.SETTINGS),
						})}
						data-to={RoutePaths.SETTINGS}
						onClick={redirect}
					>
						<img src={require('@/assets/svg-icons/settings.svg')} />
						<span>{t('navbar.menu.settings.pipeline')}</span>
					</a>

					<a
						href={BC_HELP_URL}
						target="_blank"
						className="navbar-mobile-item help-link"
						onClick={handleInfoClickOnMobile}
						rel="noreferrer"
					>
						<img src={require('@/assets/svg-icons/question.svg')} />
						<span>{t('navbar.menu.help')}</span>
					</a>
				</div>
			</div>
		</nav>
	)
}

export default Navbar
