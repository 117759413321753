export enum RouteNames {
	ROOT = 'Root',
	WELCOME = 'Welcome',
	ERROR = 'error',
	SANDBOX = 'sandbox',
	NOT_FOUND = 'not-found',
	CONNECTION = 'Connection',
	PAYWALL = 'Paywall',
	SETTINGS = 'Settings',
}

export enum RoutePaths {
	CONNECTION = 'connections',
	SETTINGS = 'settings',
	BILLING = 'billing',
}
