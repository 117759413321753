import { xhrs } from './xhrs'
import queryString from 'query-string'
import { Properties } from '@/types/connection'

export const updateGaProperties = async (properties: Properties) => {
	const query = queryString.stringify(properties)

	return await xhrs({
		url: `/tracking/setup?${query}`,
		method: 'POST',
	})
}
