import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import multiguard from 'vue-router-multiguard'

import { isAuthenticated } from './guards'
import resolvers from './resolvers'
import { RouteNames } from '@/types/routes'

const routes: Array<RouteRecordRaw> = [
	{
		path: '/',
		name: RouteNames.ROOT,
		component: () => import(/* webpackChunkName: "root" */ '../views/Root.vue'),
		beforeEnter: isAuthenticated,
	},
	{
		path: '/survey',
		name: RouteNames.WELCOME,
		component: () => import(/* webpackChunkName: "welcome" */ '../views/Welcome.vue'),
		beforeEnter: multiguard([
			isAuthenticated,
			resolvers.hasRegistrationDetails,
			resolvers.hasUserDetails,
			resolvers.hasOnboardingEntry,
		]),
	},
	{
		path: '/error',
		name: RouteNames.ERROR,
		component: () => import(/* webpackChunkName: "error" */ '../views/ErrorPage.vue'),
	},
	{
		path: '/sandbox',
		name: RouteNames.SANDBOX,
		component: () => import(/* webpackChunkName: "sandbox" */ '../views/SandboxPage.vue'),
	},
	{
		path: '/:pathMatch(.*)*',
		name: RouteNames.NOT_FOUND,
		redirect: '/',
	},
	{
		path: '/connections',
		name: RouteNames.CONNECTION,
		component: () =>
			import(/* webpackChunkName: "connection" */ '../views/connection/Connection.vue'),
		beforeEnter: multiguard([
			isAuthenticated,
			resolvers.hasUserDetails,
			resolvers.hasPaymentDetails,
		]),
	},
	{
		path: '/paywall',
		name: RouteNames.PAYWALL,
		component: () => import(/* webpackChunkName: "paywall" */ '../views/paywall/Paywall.vue'),
		beforeEnter: multiguard([
			isAuthenticated,
			resolvers.hasUserDetails,
			resolvers.hasPaymentDetails,
		]),
	},
	{
		path: '/settings',
		name: RouteNames.SETTINGS,
		component: () =>
			import(/* webpackChunkName: "settings" */ '../views/../views/settings/Settings.vue'),
		beforeEnter: multiguard([
			isAuthenticated,
			resolvers.hasUserDetails,
			resolvers.hasSettingsDetails,
		]),
	},
]
const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
})

export default router
