export default {
	common: {
		store: 'Store',
		email: 'Email',
		next: 'Next',
		required: 'All fields are required',
		continue: 'Continue',
		loading: 'Loading ...',
		save: 'Save',
		unauthorized: 'Your token has expired. Please refresh the page.',
	},
	navbar: {
		menu: {
			connections: 'Connections',
			settings: {
				title: 'Settings',
				billing: 'Billing',
				pipeline: 'Data pipeline',
			},
			help: 'Help',
		},
	},
	welcome: {
		thankYouTitle: "You're all set up! No further action required.",
		title: 'Welcome to Littledata!',
		storeName: {
			label: '@:common.store name',
			placeholder: '@:common.store name',
		},
		storeUrl: {
			label: '@:common.store URL',
			placeholder: '@:common.store URL',
			error: '@:welcome.storeUrl.label should be a valid link',
		},
		notificationEmail: {
			label: '@:common.email Notifications',
			placeholder: "jane{'@'}yourstore.com",
			error: 'Please use a valid email address',
			terms: 'All the important notifications will be sent here.',
		},
		survey: {
			aboutUs: {
				question: 'How did you hear about us?',
				placeholder: 'Write here your answer...',
			},
			role: {
				question: 'Which of the following best describes your role?',
				options: {
					ceo: 'ceo / founder',
					cto: 'cto / developer',
					brandManager: 'ecommerce / brand manager',
					marketingManager: 'marketing manager / ppc',
					analyst: 'analyst / data scientist',
					other: 'other',
				},
			},
			agency: {
				question: 'Are you part of an agency or a consulting firm?',
				options: {
					yes: 'yes',
					no: 'no',
				},
			},
			hopes: {
				question: 'What do you hope to get out of Littledata? (choose all that apply)',
				options: [
					'Understand which marketing campaigns are most effective (ROI)',
					'Understand where my customers are coming from',
					'Know the LTV and CAC by campaign and source',
					'Differentiate first time orders from subscription orders',
					"Send my store's analytics data to GA",
					"Send my store's analytics data to Segment",
					"Send my store's analytics data to a data warehouse",
					'Match my revenue and transactions from BIGCOMMERCE with Google Analytics',
					'Something else',
					'Not sure, just exploring',
				],
			},
			cta: 'Install Google Analytics',
		},
	},
	connection: {
		name: 'Google Analytics',
		steps: {
			property: {
				title: 'Insert your Web Property ID',
				points: {
					step1: 'Go to your <a href="https://store-{storeHash}.mybigcommerce.com/manage/data-solutions" target="_blank"><strong>BigCommerce Data Solutions admin</strong></a>',
					step2: 'Click <strong>Google Analytics</strong>',
					step3: 'Copy the <strong>Google Analytics web property ID</strong> starting with <strong>UA-</strong>',
					step4: 'Paste it into the dedicated input field',
				},
				webProperty: {
					label: 'Property ID',
					placeholder: 'UA-1234567-1',
					error: 'Invalid Property ID',
				},
			},
			gaSelection: {
				title: 'Which Google Analytics version would you like to send the data to?',
			},
			ga4: {
				points: {
					measurementId: {
						step1: 'Go to your Google Analytics property admin',
						step2: 'Select <strong>Data Streams</strong>',
						step3: 'Click on the Web data stream you wish to send data to <a href="https://help.littledata.io/posts/create-a-data-stream-in-ga4/?_ga=2.216424657.696456757.1667552213-1016156415.1666266616" target="_blank"><strong>See how to create a data stream</strong></a>',
						step4: 'Copy the Measurement ID starting with <strong>G-</strong>',
						step5: 'Paste the Measurement ID here',
					},
					apiSecret: {
						step1: 'Open the <strong>Data stream</strong> provided in the last step',
						step2: 'Go to <strong>Additional settings > Measurement Protocol API secrets</strong>',
						step3: 'Click <strong>Create</strong>',
						step4: 'Choose a name e.g. <strong>Littledata API secret</strong>',
						step5: 'Paste the secret value here:',
					},
				},
				title: 'Connect Google Analytics 4',
				measurementId: {
					placeholder: 'e.g.: G-123456',
					label: 'Measurement ID',
					error: 'Invalid Measurement ID',
				},
				apiSecret: {
					placeholder: 'e.g.: 0fTvUytcRgibBiOe0uzmYw',
					label: 'Measurement Protocol API secret',
					error: 'Invalid Measurement Protocol API secret',
				},
			},
			nativeGA: {
				title: 'Disable native Google Analytics tracking',
				points: {
					step1: 'Go to your <a href="https://store-{storeHash}.mybigcommerce.com/manage/data-solutions" target="_blank"><strong>BigCommerce Data Solutions admin</strong></a>',
					step2: 'Click <strong>Google Analytics</strong>',
					step3: 'Click the <strong>Disconnect</strong> button',
					step4: 'Confirm by clicking <strong>Disconnect</strong> again on the modal that pops up',
				},
			},
			loading: {
				title: 'Updating Tracking Code',
			},
			success: {
				title: 'You are all set.',
				subtitle: 'The following have been applied to your store:',
				description: 'You can view the data in ',
				benefits: [
					'Enhanced ecommerce tracking is on',
					'Client-side script has been applied',
					'Server-side webhooks have been applied',
					'Marketing attribution is being tracked',
				],
				cta: 'Launch @:connection.name',
				planStatus: 'Your current payment plan is',
				planStatusPrice: '{planName} (${amount}/{recurring})',
				planStatusTrial:
					'You have {daysLeft} days left in the free trial. Starting {startingDate} you will be charged',
				planStatusTrialPrice: '${amount}/{recurring} ({planName} Plan)',
				support: 'For any support queries please reach out to',
				supportEmailSubject: '[BigCommerce] I have a question',
			},
		},
	},
	error: {
		defaultMessage: 'Something went wrong!',
	},
	paywall: {
		title: 'Try Littledata free for 30 days',
		recommended: 'best for you',
		andUp: 'and up',
		ctas: {
			overLimit: 'over limit',
			start: 'start free trial',
			reactivate: 'reactivate',
			chat: "let's chat",
			pay: 'pay',
		},
		orders: 'orders',
		upTo: 'up to',
		noOfOrders: '{orders} @:paywall.orders',
		unlimited: 'unlimited',
		emailSubject: 'Littledata {planName} Plan for BigCommerce',
		infoNote:
			'Average monthly orders between { fromDate } and { nowDate } exceeds the plan limit of { limit }',
	},
	settings: {
		holding: {
			title: 'Data Pipeline Settings',
			paragraph_1:
				'You need to first complete the installation to be able to configure the settings',
			paragraph_2: 'If this is an error, then please contact customer support at',
			cta: 'Complete installation',
		},
		header: {
			storeUrl: 'Store URL',
			webPropertyId: 'GA Property ID',
			measurementId: 'Measurement ID',
		},
		sectionTitle: 'Data Pipeline Settings',
		ga4: {
			title: 'Google Analytics 4',
			description:
				'We recommend all stores to track Universal Analytics and Google Analytics 4 in parallel. There is no additional cost to using Littledata for both destinations.',
			measurementIdLabel: 'Measurement ID',
			measurementIdPlaceholder: 'e.g.: G-123456',
			measurementIdError: 'Invalid Measurement ID',
			apiSecretLabel: 'Measurement Protocol API secret',
			apiSecretPlaceholder: 'e.g.: 0fTvUytcRgibBiOe0uzmYw',
			apiSecretError: 'Invalid Measurement Protocol API secret',
		},
		facebookCapi: {
			title: 'Facebook Conversion API',
			description:
				'Change the Facebook Ads account (linked to a Facebook Pixel) that your data is sent to',
			pixelIdLabel: 'Pixel ID',
			pixelIdPlaceholder: 'e.g. 123456789000',
			pixelIdError: 'Invalid Pixel ID',
			accessTokenLabel: 'Access Token',
			accessTokenPlaceholder: 'e.g. EDA54FSD....',
			accessTokenError: 'Invalid Access Token',
		},
		webPropertyId: {
			title: 'Property ID',
			description:
				'This field instructs the gtag library towards which property ID to send the data',
			placeholder: 'e.g. UA-123456-2',
			error: 'Invalid Property ID',
		},
		anonymizeIp: {
			title: 'Anonymize IP',
			description:
				"This field instructs the gtag library to mask the last 3 numbers of the user's IP address, to support greater user privacy at the expense of some geo-location accuracy.",
		},
		googleSignals: {
			title: 'Google Signals',
			description:
				'This field opts your site into using Google Signals, to enable more powerful demographic reports and cross-device tracking using the Google Ads cookie. You need to check this is compatible with your website terms of use.',
		},
		linkerDomains: {
			title: 'Extra Linker Domains',
			description:
				'To enable cross-domain tracking on pages that your Shopify store links to (destination domains) you can add an extra array of domains to the data layer. You do not need to add domains here which only send traffic to your Shopify store (source domains) - by default this script will look for the linker URL parameters. You will also need to enable the Google Analytics linker on those source or destination domains.',
			placeholder: 'e.g. mydomain.com',
			error: 'Please use a valid value. Expected format: e.g. mylandingpage.com',
			buttonCta: 'Add another domain',
		},
		successNotificationTitle: '{settingName} was updated',
	},
}
