import { UserInfoStore } from './user/info'
import { SettingsPipelineStore } from './settings/pipeline'
import { BillingStore } from './payment/billing'

export class RootStore {
	public userInfoStore: UserInfoStore
	public settingsPipelineStore: SettingsPipelineStore
	public billingStore: BillingStore

	constructor() {
		this.userInfoStore = new UserInfoStore()
		this.settingsPipelineStore = new SettingsPipelineStore()
		this.billingStore = new BillingStore()
	}
}
