import { getOnboardingEntry } from '@/api/onboarding-entry'
import { getRoutineModule } from '@/store/routine'

import remove from './remove'

const module = getRoutineModule('getOnboardingEntry', () => {
	return getOnboardingEntry()
})

export default {
	...module,
	modules: {
		remove,
	},
}
