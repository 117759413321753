import { UserSurvey } from '@/types/survey'
import { xhrs } from './xhrs'

interface RegistrationResponse {
	notificationEmail: string
	survey: UserSurvey
}

export const getRegistrationDetails = async (): Promise<RegistrationResponse> => {
	return await xhrs({
		url: '/registration',
		method: 'GET',
	})
}
