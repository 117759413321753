import queryString from 'query-string'

import { xhrs } from './xhrs'
import { Store, User } from '@/types/auth'
import { UserDataUpdate } from '@/types/user'

export interface AuthResponse {
	user: User
	store: Store
}

export interface TokenResponse {
	token: string
}

export interface UserResponse {
	notificationEmail: string
	survey: object
}

export const getUserInfoRequest = async (): Promise<AuthResponse> => {
	return await xhrs({
		url: '/user-and-store',
		method: 'GET',
	})
}

export const getSandboxTokenRequest = async (storeHash: string): Promise<string> => {
	const result: TokenResponse = await xhrs({
		url: `/sandbox/token?${queryString.stringify({ storeHash })}`,
		method: 'GET',
	})

	return result.token
}

export const updateUserDetails = async (userData: UserDataUpdate): Promise<UserResponse> => {
	return await xhrs({
		url: '/registration',
		method: 'POST',
		body: userData,
	})
}
