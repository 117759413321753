import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Menu, Popup } from '@littledata/ld-frontend-components-react'
import { useTranslation } from 'react-i18next'
import { RoutePaths } from '@/types/routes'
import { useRootStore } from '@/react/providers/RootStoreProvider'

interface Props {
	targetRef: React.MutableRefObject<HTMLElement> | any
	closeMenu: () => void
	onRoute: (path: string) => void
}

export const SettingsMenu: React.FC<Props> = observer((props: Props) => {
	const { billingStore } = useRootStore()
	const root = document.getElementById('popup-root')
	const { t } = useTranslation()
	const onBillingItemClick = () => billingStore.fetch({})

	useEffect(() => {
		if (billingStore.successed && billingStore.data?.sessionUrl && window?.top) {
			window.top.location.href = billingStore.data?.sessionUrl
		}
	}, [billingStore.data, billingStore.successed])

	// hack to redirect by Vue.@todo remove after migrate to react.
	const redirect = (path: string) => {
		if (path) {
			props.onRoute(path)
			props.closeMenu()
		}
	}

	return (
		<Popup
			root={root!}
			target={props.targetRef.current!}
			onClickOutside={props.closeMenu}
			modalOpacity={0}
		>
			<Menu>
				<Menu.Header>
					<h2>{t('navbar.menu.settings.title')}</h2>
				</Menu.Header>
				<Menu.Items>
					<Menu.Item itemId="billing" onClick={onBillingItemClick}>
						{t('navbar.menu.settings.billing')}
					</Menu.Item>
					<Menu.Item itemId="data-pipeline" onClick={() => redirect(RoutePaths.SETTINGS)}>
						{t('navbar.menu.settings.pipeline')}
					</Menu.Item>
				</Menu.Items>
			</Menu>
		</Popup>
	)
})
