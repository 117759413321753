export interface RootState {}

export enum Status {
	IDLE = 'idle',
	LOADING = 'loading',
	LOADED = 'loaded',
}

export const state = {
	tokenExpired: false,
}

export interface RoutineState<DataType, ErrorType = unknown> {
	data: DataType | null
	error: ErrorType | null
	state: Status
}

export const getRoutineState = <DataType, ErrorType>() => {
	const state: RoutineState<DataType, ErrorType> = {
		data: null,
		error: null,
		state: Status.IDLE,
	}

	return state
}
